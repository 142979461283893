import $ from "jquery";

const $doc = $(document);

function ornamentPlacement(element, ornament) {
  let distanceFromTop;
  const isMobile = window.matchMedia("(max-width: 700px)").matches;
  const isTablet = window.matchMedia(
    "(min-width: 701px) and (max-width: 900px)",
  ).matches;
  const isXl = window.matchMedia("(min-width: 1200px)").matches;
  const blockYpos = element.offset().top;
  const height = element.height();
  var viewportWidth = $(window).width();
  var variableValue = (viewportWidth / 1400) * 80;
  variableValue = Math.max(50, Math.min(variableValue, 80));
  distanceFromTop = height + blockYpos - variableValue;

  if (element.hasClass("is-style-hero-var-2") && !isMobile && !isTablet) {
    distanceFromTop = distanceFromTop + 40;
  }
  let ornamentParams = {
    position: "absolute",
    top: distanceFromTop - 20 + "px",
    width: "29.8vw",
    left: "-2vw",
    opacity: "1",
    zIndex: 1,
  };
  if (isTablet) {
    ornamentParams.width = "20vw";
    ornamentParams.left = "-1vw";
    ornamentParams.opacity = "1";
    ornamentParams.top = distanceFromTop - 50 + "px";
  }
  if (isXl) {
    ornamentParams.width = "10vw";
    ornamentParams.left = "6.85vw";
    ornamentParams.opacity = "1";
    ornamentParams.top = distanceFromTop - 40 + "px";
  }

  if (!isMobile && !isTablet && !isXl) {
    ornamentParams.width = "16vw";
    ornamentParams.left = "6.85vw";
    ornamentParams.opacity = "1";
    ornamentParams.top = distanceFromTop - 40 + "px";
  }
  ornament.css(ornamentParams);
  ornament.insertAfter(element);
  ornament.show();
}

function initHero() {
  const hero = $(".js-hero");
  const heroParent = $("header.hero-js");

  const heroBox = $(".js-hero__box");
  const breadcrumbs = $(".js-breadcrumbs");
  const ornament = $(".js-ornament");
  if (!heroBox.length && !hero.length) {
    return;
  }
  if (breadcrumbs.length) {
    heroBox.append(breadcrumbs);
    breadcrumbs.removeClass("hidden");
  }
  if (ornament.length) {
    if (
      heroParent.hasClass("is-style-hero-var-1") ||
      heroParent.hasClass("is-style-hero-var-2") ||
      heroParent.hasClass("is-style-hero-var-4")
    ) {
      ornamentPlacement(heroParent, ornament);
    } else {
      heroBox.append(ornament);
      ornament.show();
    }
  }
}

$doc.ready(initHero);

$(window).on("resize", updateOrnamentPosition);

function updateOrnamentPosition() {
  const heroParent = $("header.hero-js");
  const ornament = $(".js-ornament");
  if (heroParent.length>0 || ornament.length>0) {
    ornamentPlacement(heroParent, ornament);
  }
}
